'use client';
import { forwardRef } from 'react';

type Props = {
  children?: React.ReactNode;
};

const VipRefContainer = forwardRef<HTMLDivElement, Props>(({ children }) => (
  <div
    className="h-full overflow-x-hidden overflow-y-scroll bg-goku md:h-screen xl:overflow-scroll"
    id="vip-main-container"
  >
    {children}
  </div>
));

export default VipRefContainer;
